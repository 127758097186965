.projects-root-container {
  margin: 5% 10%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px;
}

.project-card {
  background-color: rgb(224, 224, 224);
  box-shadow: 0 0 10px black;
  flex: 1 1 30%;
  /* border-radius: 5px; */
  border: none;
  transition: transform .2s;
  max-width: 350px;
  
  min-height: 250px;
  display: flex;
  justify-content: space-between;
  justify-content: flex-start;
  flex-direction: column;
}

.project-card:hover {
  transform: scale(1.05)
}

.project-card:last-child {
  flex: 0 1 32%;
}
.project-card:nth-last-child(2) {
  flex: 0 1 32%;
}



.project-image-container {
  display: flex;
}

.project-image {
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  aspect-ratio: 16-9;
  /* border-bottom: 1px solid black; */
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  /* box-shadow:0 0 3px black; */
}

.project-description-container {
  display: flex;
  flex-direction: column;
  /* padding: 0 10px; */
  /* justify-content: flex-start; */
  min-height: 150px;
  flex-grow: 1;
}

.project-title {
  margin: 0;
  padding: 0 10px;
  font-weight: 400;
}

.project-description {
  margin: 0;
  padding: 0 10px;
  flex-grow: 1;
  font-weight: 400;
  /* font-size: 16px; */
}

.project-button {
  display:block;
  width:100%;
  background-color: green;
  color: white;
  border: none;
  transition: 0.2s;
  cursor: pointer;
  height: 30px;
}
.project-button:hover {
  background-color: rgba(0, 124, 41, 0.9)
}

.project-button a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
  /* background-color: black; */
  
}

@media (max-width: 768px) {
  .project-card {
    flex: 1 1 100%;
    margin: 0 20%;
    /* max-width: 300px;
    min-width: 300px; */
  }
  .project-card:last-child {
    flex: 1 1 32%;
  }
}

@media (max-width: 375px) {
  .project-card {
    flex: 1 1 100%;
    margin: 0 0%;
    /* max-width: 200px;
    min-width: 200px; */
    
  }
  .project-card:last-child {
    flex: 1 1 32%;
  }
}