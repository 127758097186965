.navbar {
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px black;
  z-index: 599;
}

.navbar * {
  text-decoration: none;
  color:white;
}

.navbar-left {
  display: flex;
}
.navbar-middle {
  flex-grow: 1;
}
.navbar-right {
  display: flex;
}
.navbar-title {
  margin-left: 10px;
}
.navbar-menu-hamburger {
  color: inherit;
  padding: 20px;
  display: none;
  margin-right: 10px;
  cursor: pointer;
}

.navbar-menu-buttons {
  padding: 10px 14px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu-button a {
  padding: 20px;
  height: 100%;
}

.navbar-menu-button:last-child {
  /* margin-right: 1px; */
}

.navbar-menu-button a:hover {
  background-color: rgba(0, 98, 0, 0.8);
  border-radius: 5px;
  transition: 0.3s ease;
}

.navbar-sidebar {
  display: none;
}

@media (max-width: 768px) {
  .navbar-right {
    display: none;
  }
  .navbar-menu-hamburger {
    display: flex;
    background-color: inherit;
    border: none;
    z-index: 99999;
  }
  .navbar-sidebar {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 100px 0;
    inset: 0 0 0 30%;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    font-size: 24px;
    text-align: center;
    z-index: 9999;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
  .navbar-sidebar-button {
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
  }
  .navbar-sidebar-button:last-child {
    border-bottom: none;
  }
  .navbar-sidebar-button a {
    width:100%;
    /* height:100%; */
    padding: 30px 0;

  }
  .navbar-sidebar-button a:hover {
    background-color: rgb(100, 100, 100);
    transition: 0.3s ease;
  }
}