.notfound-container {
  margin: 0;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}

.notfound-main {
  /* background-color: antiquewhite; */
  margin: 10% 5%;
  width: 100%;
  /* height: 50vh;
  width: 100vh; */
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid black; */
  /* box-shadow: 0 0 10px black; */
  /* width: 100vh;
  height: 100vh; */
  
}

.notfound-main button {
  width: 40%;
  display: flex;
  padding: 0;
  background-color: green;
  /* height: 30px; */
  border: none;
  box-shadow: 0 0 10px black;
}
.notfound-main button a{
  width: 100%;
  margin: 0;
  /* height: 30px; */
  padding: 5px;
  /* background-color: black */
  text-decoration: none;
  color: white;
}

.notfound-main button a:hover{
  background-color: darkgreen;
}
.notfound-main button a svg{
  font-size: 24px
}

.notfound-main img {
}

@media (max-width: 768px) {
  .notfound-main img {
    width: 75%;
    height: 75%
  }
}

@media (max-width: 540px) {
  .notfound-main img {
    width: 60%;
    height: 60%
  }
  .notfound-main button a svg{
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .notfound-main img {
    width: 60%;
    height: 60%
  }
  .notfound-main h1 {
    font-size: 24px;
  }
  .notfound-main button a svg{
    font-size: 10px
  }
  .notfound-main button a{
    padding: 0;
  }
}