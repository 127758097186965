.contact-root-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  /* overflow:hidden; */
  /* flex-wrap: wrap; */
  background-image: url("../../images/contact/nalgene.jpg");
  background-size:cover;
  background-position:center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

/* .contact-left-container {
  flex: 1 1 60%;
  background-color: darkgreen;
  background-image: url("../../images/contact/nalgene.jpg");
  background-size:cover;
  background-position:center;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100vh;
} */
/* .contact-right-container {
  flex: 1 1 100%;
  background-color: rgba(0, 128, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 50vh;
} */

.contact-title-container {
  
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin: 10%;
  gap: 10px;
  /* padding: 30px; */
  flex-wrap: wrap;
  /* background-color:white; */
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  width:100%;
  height: 100%;
  padding: 50px 0;
}

.contact-title-container h2 {
  text-align: center;
  margin: 0 10%;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  /* backdrop-filter: blur(5px); */
}
.contact-title-container h5 {
  text-align: center;
  margin: 0 10%;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}

.contact-title-container form input {
  width: 100%;
  height: 30px;
  margin: 5px 0;
}

.contact-title-container form textarea {
  width: 100%;
  height: 100px;
  margin: 10px 0;
}
.contact-title-container form button {
  width: 100%;
  height: 30px;
  /* margin: 10px 0; */
  background-color: darkgreen;
  color: white;
}

@media (max-width: 768px) {
  .contact-left-container {
    flex: 1 1 100%;
  }

  .contact-right-container {
    flex: 1 1 100%;
  }

  .contact-title-container {
    margin: 0;
    flex-wrap: nowrap;
    width: 100%;
  }
  .contact-title-container * {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-title-container * {
    margin: 0;
    width: 80%;
  }

}