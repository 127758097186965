.about-root-container * {
  margin: 0;
  text-decoration: none;
}

.about-hero {
  background-image: url("../../images/about/profile/bannerprofile2.jpg");
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.about-hero-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: rgba(20,20,20,0.4);
  height: 100%;
  color: white;
  backdrop-filter: blur(2px);
}


.about-hero-title {
  margin: 20px;
  font-size: 48px;
  /* position: absolute;
  top: 40px; */
  text-align: center;
}
.about-hero-subtitle {
  /* position: absolute;
  top: 120px; */
}

.about-hero-container button {
  position: absolute;
  bottom: 20px;
}

.about-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 50px auto;
  padding: 50px auto;
}

.about-container h1 {
  padding: 20px;
}
.about-container p {
  padding: 20px;
}

.about-container:nth-child(even) {
  background-color: rgb(245, 255, 245)
}
.about-container:nth-child(odd) {
  /* background-color: lightblue */
}

.about-left-container {
  flex: 1 1 60%;
  margin: 20px;
}
.about-right-container {
  flex: 1 1 40%;
  margin: 20px;
}

.about-card-container {
  display: flex;
  flex-wrap: wrap;
}

.about-card {
  flex: 1 1 33%;
  margin: 20px;
  padding: 50px;
  box-shadow: 0 0 10px grey;
  width: 100%;
  height: 100px;
  text-align: center;
  color: darkgreen;
}

.about-image-collage {
  width: 100px;
  height: 100px;
}

.about-image-adventure-collage-container {
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
}

.about-image-adventure-collage {
  flex: 1 1 30%;
  width: 100px;
  height: 150px;
}

.about-body-button {
  display: inline-block;
  /* flex-wrap: nowrap; */
  box-shadow: 0 0 10px black;
  max-width: 200px;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  /* align-self: flex-start */
  /* flex-basis: auto */
  padding: 20px 0;
  margin: 10px 20px;
}

.about-body-button a {
  /* width: 100%; */
  padding: 20px 60px;
  height: 100%;
  width: 100%;
  color: white;
  /* background-color: rgba(0,0,0,0.8); */
}

.about-body-button a:hover {
  background: rgba(255,255,255,0.1);
}

.about-body-button:nth-child(3) {
  background-color: green;
}

.about-body-button:nth-child(4) {
  background-color: darkgreen;
}

.code-icons {
  font-size: 72px;
}

.code-icons svg:nth-child(even) {
  color: green;
}

.about-profile-img {
  border-radius: 9999px;
}
@media (max-width: 768px) {
  .about-profile-img {
    width: 200px;
    height: 200px;
    border-radius: 9999px
  }
  .code-icons {
    font-size: 60px;
  }
  .about-image-collage {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 459px) {
  .code-icons {
    font-size: 40px;
  }
  .about-image-collage {
    width: 40px;
    height: 40px;
  }
  .about-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 9999px
  }
}

@media (max-width: 375px) {
  .about-left-container {
    flex: 1 1 70%;
  }
  .about-right-container {
    flex: 1 1 30%;
  }
  .about-container h1 {
    font-size: 24px;
  }
}