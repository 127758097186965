.resume-root-container {
  display: flex;
  box-shadow: 0 0 20px black;
  margin: 5% 10%;
  gap: 2%;
  padding: 2%;
  /* justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
  /* max-width: 1980px; */
}

.resume-left-container {
  display: flex;
  flex: 1 1 28%;
  flex-wrap: wrap;
  flex-direction: column;
}

.resume-right-container {
  display: flex;
  flex: 1 1 68%;
  flex-wrap: wrap;
  flex-direction: column;
}

.resume-left-heading-container {
  /* padding: 0 2px; */
  /* border: 1px solid black; */
  margin: 0;
}
.resume-right-heading-container {
  /* border: 1px solid black; */
  margin: 0;
}

.resume-profile-image {
  width: 100%;
  height: 200px;
  max-width: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 999px;
  box-shadow: 0 0 5px black;
}

.resume-title h2 {
  font-weight: 400;
}

.resume-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.resume-contact h4 {
  margin: 0;
  display: flex;
  /* justify-content: center; */
  /* align-items: left; */
}

.resume-contact a {
  text-decoration: none;
  color: inherit;
}
.resume-contact svg {
  font-size: 20px;
  color: green;
}

.resume-summary p {
  /* text-align: justify; */
}

.resume-skills p {
  margin: 5px 0;
}

.resume-skills {
  margin-bottom: 10px;
}

.resume-attributes-list {
  list-style: none;
  margin: 10px 0;
  /* margin-bottom: 3px; */
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.resume-attributes-item {
  background-color: green;
  color: white;
  border-radius: 20px;
  margin: 2px 5px;
  padding: 1px 10px;
  box-shadow: 0 0 3px black;
  font-size: 14px;
}

.resume-attributes-item svg {
  vertical-align: middle;
  margin-bottom: 4px;
}

.resume-section-body-even {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items:flex-start;
  /* align-items:flex-start; */
  /* vertical-align: center; */
  /* vertical-align: middle; */

}

.resume-section-body-even *:first-child{
  flex: 1 1 10%;
  margin: 0;
  margin-top: 3px;
  
}
.resume-section-body-even *:nth-child(2n){
  flex: 1 1 60%;
  margin: 0;
}
.resume-section-body-even *:nth-child(3n){
  flex: 1 1 20%;
  margin: 0;
  margin-top: 3px;
}

.resume-section-title {
  margin: 0;
  background-color:rgba(0, 128, 0, 0.1);
  font-weight: 400
}

.resume-section-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:flex-start;
  /* gap: 10px; */
}

.resume-section-header {
  margin: 0;
  margin-top: 10px;
}

.resume-section-body h4:first-child {
  width: 80%;
  margin-bottom: 2%;
}

.resume-section-body h6:nth-child(2n) {
  width: 20%;
  margin-bottom: 2%;
  flex-grow: 1;
  text-align: right;
}
.resume-section-body *:nth-child(3n) {
  width: 100%;
  margin: 0;
}

.resume-section-body ul {
  margin: 0;
  padding-left: 25px;
}

.resume-section-body ul li {

}

.resume-section-body-simple {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:flex-start;
}

.resume-section-body-simple *:first-child{
  flex: 1 1 100%;
  margin-top: 10px;
}
.resume-section-body-simple *:nth-child(2n){
  flex: 1 1 100%;
  margin: 0;
}

.resume-section-body-simple a {
  color: green;
  text-decoration: none;;
}

.resume-root-container-pdf {
  display: flex;
  flex-direction: column;
  margin: 5% 10%;
}

.resume-pdf-pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
  /* height: 100% !important; */
}

.resume-pdf-pagination-container button {
  margin: 5px;
  padding: 0;
  cursor: pointer;
  height: 20px;
  color: white;
  background-color: green
}

.resume-root-container-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 30% 0% 30%;
  gap: 20px;
}

.resume-root-container-buttons button {
  /* padding: 10px; */
  color: white;
  background-color: green;
  /* cursor: pointer; */
  display: inline-block;
  max-width: 200px;
  align-items: center;
  justify-content: center;
}

.resume-root-container-buttons button a {
  padding: 0 10px;
  height: 100%;
  width: 100%;
  /* background-color: white; */
  text-decoration: none;
  color: inherit;
}

@media (max-width: 981px) {
  .resume-profile-image {
    width: 200px;;
    height: 200px;
  }
  .resume-root-container {
    margin: 5%;
    transition: margin 200ms;
  }
}

@media (max-width: 375px) {
}