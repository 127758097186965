.landing-container * {
  margin: 0;
  text-decoration: none;
}

.landing-container a {
  color: white;
}

.hero {
  background-image: url("../../images/hero/frontenac.jpg");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.landing-title-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: rgba(20,20,20,0.4);
  height: 100%;
}

.landing-title {
  font-size: 60px;
  /* margin: 0 100px; */
  margin-top: 80px;
  color: rgb(255, 255, 255);
}
.landing-subtitle {
  font-size: 30px;
  margin: 0 100px;
  color: rgb(219, 219, 219);
  text-align: center;
}

.landing-buttons-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.landing-button {
  display: inline-block;
  box-shadow: 0 0 10px black;
  max-width: 200px;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  /* background: rgb(0, 255, 0, 0.3); */
  padding: 20px 0;
  margin: 10px 20px;
}

.landing-button a {
  padding: 20px 60px;
  height: 100%;
  width: 100%;
}

.landing-button a:hover {
  background: rgba(255,255,255,0.1);
}

.landing-button:nth-child(1) {
  background-color: green;
}

.landing-button:nth-child(2) {
  background-color: darkgreen;
}

/* .landing-button:nth-child(1) a {
  color: white;
}

.landing-button:nth-child(2) a {
  color: black;
} */

@media (max-width: 768px) {
  .landing-button a{
    /* padding: 10px 40px;
    margin: 0; */
  }

}
@media (max-width: 613px) {
  .landing-button {
    /* display: none; */
  }
}
@media (max-width: 375px) {
  .landing-button {
    /* display: none; */
    padding: 10px;
  }
  .landing-title {
    font-size: 48px;
  }
  .landing-subtitle {
    font-size: 20px;
  }
}